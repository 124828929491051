import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

export const useGetCurrentPage = ():
  | 'Order'
  | 'Accessories'
  | 'Membership'
  | 'Gift Sign In'
  | 'Receipt'
  | 'Retry'
  | 'Password'
  | 'Join' => {
  const pathname = usePathname();

  return useMemo(() => {
    if (pathname.includes('/order')) {
      return 'Order';
    } else if (pathname.includes('/accessories')) {
      return 'Accessories';
    } else if (pathname.includes('/membership')) {
      return 'Membership';
    } else if (pathname.includes('/giftsignin')) {
      return 'Gift Sign In';
    } else if (pathname.includes('/receipt')) {
      return 'Receipt';
    } else if (pathname.includes('/retry')) {
      return 'Retry';
    } else if (pathname.includes('/password')) {
      return 'Password';
    }
    return 'Join';
  }, [pathname]);
};
