'use client';

import { useShallow } from 'zustand/react/shallow';
import Pagination from 'ui/components/Pagination/Pagination';
import { useTranslation } from '@whoop/i18n/lang/client';
import { useMemo } from 'react';
import { AmplitudeEvents } from 'whoop-analytics/lib/types';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { useGetCurrentPage } from '@/hooks/getCurrentPage';
import { useNavigation } from '@/hooks/navigation';
import { useAnalytics } from '@/hooks/analytics';
import { env } from 'env';
import { TRIAL } from '@/lib/constants/memberships';
import { Header } from './Header';

function PaginationHeader(): JSX.Element {
  const { getLocalizedPath } = useNavigation();
  const { trackAmplitudeEvent } = useAnalytics();

  const { catalog, toggleIsCartOpen, cart, isSkipMemSelectFlow } = useSiteStore(
    useShallow((state) => ({
      catalog: state.ecommCatalog,
      toggleIsCartOpen: state.toggleIsCartOpen,
      cart: state.cart,
      isSkipMemSelectFlow: state.isSkipMemSelectFlow,
    })),
  );

  const currentPage = useGetCurrentPage();
  const { t } = useTranslation('joinLandingPage');

  const isMembershipInCart = Boolean(
    cart.cartItems.find((item) => item.productType === 'membership'),
  );

  const showAccessories = catalog.accessories.length;
  let options = () => {
    const items = [
      {
        label: '1',
        link: getLocalizedPath(''),
        enabled: true,
        desktopText: t('header.customize'),
      },
      {
        label: '2',
        link: getLocalizedPath('membership'),
        enabled: false,
        desktopText: t('header.chooseMembership'),
      },
    ];
    if (showAccessories) {
      items.push({
        label: '3',
        link: getLocalizedPath('accessories'),
        enabled: false,
        desktopText: t('header.addAccessories'),
      });
    }

    if (isMembershipInCart) {
      items[1].enabled = true;
    } else if (currentPage === 'Membership') {
      items[1].enabled = true;
    } else {
      items[1].enabled = false;
    }

    if (showAccessories) {
      if (isMembershipInCart) {
        items[2].enabled = true;
      } else {
        items[2].enabled = false;
      }
    }

    return items;
  };

  if (isSkipMemSelectFlow) {
    options = () => {
      const items = [
        {
          label: '1',
          link: `${env.NEXT_PUBLIC_WHOOP_URL}/membership#choose-plan`,
          enabled: true,
          desktopText: t('header.chooseMembership'),
        },
        {
          label: '2',
          link: getLocalizedPath(''),
          enabled: true,
          desktopText: t('header.customize'),
        },
      ];

      if (showAccessories) {
        items.push({
          label: '3',
          link: getLocalizedPath('accessories'),
          enabled: false,
          desktopText: t('header.addAccessories'),
        });

        if (currentPage === 'Accessories') {
          items[2].enabled = true;
        } else {
          items[2].enabled = false;
        }
      }

      return items;
    };
  }

  const cartCounter = useMemo(() => {
    return cart.cartItems
      .map((product) => product.quantity)
      .reduce((prev, curr) => prev + curr, 0);
  }, [cart.cartItems]);

  const pagesWithHeader = [
    'Join',
    'Accessories',
    !isSkipMemSelectFlow && 'Membership',
  ];

  const showCart =
    currentPage === 'Membership' || currentPage === 'Accessories';
  const showCurrency = currentPage === 'Join';

  const trackClick = (name: string, number: number): void => {
    trackAmplitudeEvent(AmplitudeEvents.ClickedHeader, { name, number });
  };

  return (
    <Header
      cartCounter={cartCounter}
      showCart={showCart}
      showCountrySelector={showCurrency}
      showOrderPageV2Header={currentPage === 'Order'}
      skipMemQueryParam={isSkipMemSelectFlow ? TRIAL : ''}
      toggleIsCartOpen={toggleIsCartOpen}
    >
      <div className='absolute left-[50vw] flex -translate-x-1/2 justify-center'>
        {pagesWithHeader.includes(currentPage) &&
        catalog?.memberships.length ? (
          <Pagination
            activeIndex={0}
            items={options()}
            trackHeaderClick={trackClick}
          />
        ) : null}
      </div>
    </Header>
  );
}

export default PaginationHeader;
